import React, { useState } from 'react';
import axios from 'axios';
import { Navigate } from 'react-router-dom';
import './LoginPage.css'; 
import backgroundImage from './Loginpagebg.jpg';

function LoginPage({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false);
  // const BASE_API_URL='https://n0name-record.netlify.app/.netlify/functions/api';


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://attendance-portal-backend.onrender.com/api/login', { username, password });
      if (response.data.success) {
        setIsAuthenticated(true);   
        setLoginSuccess(true);    
      } else {
        console.log('Login failed');
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };
  if (loginSuccess) {
    return <Navigate to="/" />;
  }
  return (
    <div className="home-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <h2>Login</h2>
      <form onSubmit={handleSubmit} className="login-form">
        <input
          type="text"
          placeholder="Username or Email"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default LoginPage;
