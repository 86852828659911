import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import CheckIn from './Check-In';
import AddGuest from './Add-Guest';
import EmployeeList from './EmployeeList';
import History from './History';
import LoginPage from './LoginPage';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  return (
    <Router>
      <Routes>
        {!isAuthenticated && <Route path="/" element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} />}
        {isAuthenticated && (
          <>
            <Route path="/" element={<Home />} />
            <Route path="/checkin" element={<CheckIn />} />
            <Route path="/add-guest" element={<AddGuest />} />
            <Route path="/employee-list" element={<EmployeeList />} />
            <Route path="/history" element={<History />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
