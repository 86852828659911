import React, { useState } from 'react';
import axios from 'axios';
import './Add-Guest.css';

function AddGuest() {
  const [guestName, setGuestName] = useState('');
  const [guestMobile, setGuestMobile] = useState('');
  const [guestDestination, setGuestDestination] = useState('');
  const [guestMeetingPerson, setGuestMeetingPerson] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  // const BASE_API_URL='https://n0name-record.netlify.app/.netlify/functions/api';


  const handleAddGuest = async () => {
    try {
      let isEmployee=false
      const response = await axios.post('https://attendance-portal-backend.onrender.com/add-guest', {
        guestName,
        guestMobile,
        guestDestination,
        guestMeetingPerson,
        isEmployee, // Always set to false since we're treating as guest
      });
      console.log(response.data);
      setSuccessMessage(response.data.message);
      setErrorMessage('');
      clearFormFields();
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred while adding the guest.');
      setSuccessMessage('');
    }
  };
  
  const clearFormFields = () => {
    setGuestName('');
    setGuestMobile('');
    setGuestDestination('');
    setGuestMeetingPerson('');
  };

  return (
    <div className="add-guest-container">
      <h2>Add Guest</h2>
      
      {successMessage && (
        <p className="success-message-popup">{successMessage}</p>
      )}
      {errorMessage && <p className="error-message">{errorMessage}</p>}      
      <div className="form-group">
        <input
          type="text"
          placeholder="Guest Name"
          value={guestName}
          onChange={(e) => setGuestName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          placeholder="Guest Mobile"
          value={guestMobile}
          onChange={(e) => setGuestMobile(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          placeholder="Guest Destination"
          value={guestDestination}
          onChange={(e) => setGuestDestination(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          placeholder="Meeting Person"
          value={guestMeetingPerson}
          onChange={(e) => setGuestMeetingPerson(e.target.value)}
        />
      </div>
      <div className="form-group">
        <button className="submit-button" onClick={handleAddGuest}>
          Add Guest
        </button>
      </div>
    </div>
  );
}

export default AddGuest;
