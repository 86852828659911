import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './History.css';
import { utils, write } from 'xlsx';
function History() {
  const [employeeHistory, setEmployeeHistory] = useState([]);
  const [guestHistory, setGuestHistory] = useState([]);
  const [meetingEmployees, setMeetingEmployees] = useState({});
  const [isHistoryCleared, setIsHistoryCleared] = useState(false); // New state variable
  // const BASE_API_URL='https://n0name-record.netlify.app/.netlify/functions/api';

  useEffect(() => {
    const fetchHistoryData = async () => {
      try {
        const [employeeHistoryResponse, guestHistoryResponse] = await Promise.all([
          axios.get('https://attendance-portal-backend.onrender.com/api/get-employee-history'),
          axios.get('https://attendance-portal-backend.onrender.com/api/get-guest-history'),
        ]);

        setEmployeeHistory(employeeHistoryResponse.data);
        setGuestHistory(guestHistoryResponse.data);
      } catch (error) {
        console.error('Error fetching history data:', error);
      }
    };

    fetchHistoryData();
  }, []);

  useEffect(() => {
    const meetingEmployeeMap = {};
    employeeHistory.forEach(employee => {
      meetingEmployeeMap[employee.person_id] = employee.name;
    });

    setMeetingEmployees(meetingEmployeeMap);
  }, [employeeHistory]);

  const formattedTimestamp = timestamp => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const generateExcelData = (data) => {
    const excelData = [];

    // Push header row
    const headers = Object.keys(data[0]);
    excelData.push(headers);

    // Push data rows
    data.forEach((record) => {
      const row = Object.values(record);
      excelData.push(row);
    });

    return excelData;
  };



  const downloadExcel = (data, filename) => {
    const excelData = generateExcelData(data);
    const ws = utils.aoa_to_sheet(excelData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });
    const blob = new Blob([s2ab(wbout)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename + '.xlsx';
    a.click();
    URL.revokeObjectURL(url);
  };

  // Utility function to convert string to ArrayBuffer
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  }
  const clearHistory = () => {
    axios.post('https://attendance-portal-backend.onrender.com/api/clear-history')
      .then((response) => {
        // Handle success (e.g., show a success message)
        console.log(response.data.message);
        // Clear the history in your React state (if needed)
        setEmployeeHistory([]);
        setGuestHistory([]);
        setIsHistoryCleared(true);
      })
      .catch((error) => {
        // Handle errors (e.g., show an error message)
        console.error('Error clearing history:', error);
      });
  };
  
  return (
    <div className="history-container">
      <div className="button-container">
  <button onClick={() => downloadExcel(employeeHistory, 'Employee_History')}>
    Download Employee History
  </button>
  <button onClick={() => downloadExcel(guestHistory, 'Guest_History')}>
    Download Guest History
  </button>
  <button onClick={clearHistory}>
    Clear History
  </button>
</div>
      <div className="history-section">
        <h2>Employee History</h2>
        <table className="history-table">
          <thead>
            <tr>
              <th>Employee ID</th>
              <th>Name</th>
              <th>Mobile Number</th>
              <th>Email</th>
              <th>Check In Time</th>
              <th>Check Out Time</th>
            </tr>
          </thead>
          <tbody>
            {employeeHistory.map((employee, index) => (
              <tr key={index}>
                <td>{employee.person_id}</td>
                <td>{employee.name}</td>
                <td>{employee.mobile}</td>
                <td>{employee.email}</td>
                <td>{formattedTimestamp(employee.checkin_time)}</td>
                <td>{formattedTimestamp(employee.checkout_time)}</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="history-section">
        <h2>Guest History</h2>
        <table className="history-table">
          <thead>
            <tr>
              <th>Guest Name</th>
              <th>Mobile Number</th>
              <th>Destination</th>
              <th>Meeting Person</th>
              <th>Check In Time</th>
              <th>Check Out Time</th>
            </tr>
          </thead>
          <tbody>
            {guestHistory.map((guest, index) => {
              const meetingEmployee = employeeHistory.find(employee => employee.person_id === guest.guestMeetingPerson);
              return (
                <tr key={index}>
                  <td>{guest.name}</td>
                  <td>{guest.mobile}</td>
                  <td>{guest.destination}</td>
                  <td>{guest.meeting_person ? guest.meeting_person : 'N/A'}</td>
                  <td>{formattedTimestamp(guest.checkin_time)}</td>
                  <td>{formattedTimestamp(guest.checkout_time)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

      </div>
    </div>
  );
}

export default History;
