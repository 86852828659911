import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import backgroundImage from './1.jpg';
// import logoImage from './Logobpm.jpeg'; 

function Home() {
  return (
    <div className="home-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* <img src={logoImage} alt="Logo" /> */}

      <div className="button-container">
        <Link to="/add-guest">
          <button>Add Guest</button>
        </Link>
        
        <Link to="/checkin">
          <button>Check In</button>
        </Link>

        <Link to="/employee-list">
          <button>Employees & Guests List</button>
        </Link>

        {/* Add a link to the history page */}
        <Link to="/history">
          <button>Check History</button>
        </Link>
      </div>
    </div>
  );
}

export default Home;
