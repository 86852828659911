import React, { useState } from 'react';
import axios from 'axios';
import './Check-In.css';
function CheckIn() {
  const [employeeId, setEmployeeId] = useState('');
  const [employeeName, setEmployeeName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isEmployee, setIsEmployee] = useState(true);
  // const BASE_API_URL='https://n0name-record.netlify.app/.netlify/functions/api';

  const handleCheckIn = async () => {
    try {
      const response = await axios.post('https://attendance-portal-backend.onrender.com/api/check-in', {
        employeeId,
        employeeName,
        mobileNumber,
        email,
        isEmployee,
      });
      console.log(response.data);
      setSuccessMessage('Check-in successful!');
      setErrorMessage('');
      clearFormFields();
      
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('An error occurred during check-in.');
      setSuccessMessage('');
    }
  };
  
  

  const clearFormFields = () => {
    setEmployeeId('');
    setEmployeeName('');
    setMobileNumber('');
    setEmail('');
  };

  return (
    <div className="check-in-out-container">
      <h2>Check In</h2>
      {successMessage && <p className="success-message-popup">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <div className="form-group">
        <input
          type="text"
          placeholder="Employee ID"
          value={employeeId}
          onChange={(e) => setEmployeeId(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          placeholder="Employee Name"
          value={employeeName}
          onChange={(e) => setEmployeeName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          placeholder="Mobile Number"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
      </div>
      <div className="form-group">
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="form-group">
        <button className="submit-button" onClick={handleCheckIn}>
          Check In
        </button>
      </div>
    </div>
  );
}

export default CheckIn;
