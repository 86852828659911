import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EmployeeList.css';
function EmployeeList() {
  const [checkins, setCheckins] = useState([]);
  const [guests, setGuests] = useState([]);
  // const BASE_API_URL='https://n0name-record.netlify.app/.netlify/functions/api';

  useEffect(() => {
    const EMPLOYEES_API_URL = 'https://attendance-portal-backend.onrender.com/api/get-employees';
    const GUESTS_API_URL = 'https://attendance-portal-backend.onrender.com/api/get-guests?checkedIn=true';

    const fetchCheckinsAndGuests = async () => {
      try {
        const [employeesResponse, guestsResponse] = await Promise.all([
          axios.get(EMPLOYEES_API_URL),
          axios.get(GUESTS_API_URL),
        ]);
        setCheckins(employeesResponse.data);
        setGuests(guestsResponse.data);
        console.log("guestsResponse.data",guestsResponse.data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchCheckinsAndGuests();
  }, []);

  const handleEmployeeCheckOut = async (person_id, name, mobile, email) => {
    try {
      const response = await axios.post('https://attendance-portal-backend.onrender.com/api/check-out', {
        person_id,
        name,
        mobile,
        email,
      });
      console.log('Checkout API response:', response.data);
      await axios.post('https://attendance-portal-backend.onrender.com/api/update-checkout-time', {
        personId: person_id,
      });

      setCheckins((prevCheckins) => {
        const updatedCheckins = prevCheckins.filter((checkin) => checkin.person_id !== person_id);
        console.log('Updated check-ins after filter:', updatedCheckins);
        return updatedCheckins;
      });
      
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleGuestCheckOut = async (guestId, guestName, guestMobile, guestDestination, guestMeetingPerson) => {
    try {
      const timestamp = new Date();
      const response = await axios.post('https://attendance-portal-backend.onrender.com/api/check-out', {
        personId: guestId
      });
      console.log(response.data);
      await axios.post('https://attendance-portal-backend.onrender.com/api/update-checkout-time', {
        personId: guestId,
      });
      console.log("",guests)
      setGuests(prevGuests => prevGuests.filter(guest => guest.person_id !== guestId));
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <div className="container">
      <h2>Employee List</h2>
      <table>
        <thead>
          <tr>
            <th>Employee ID</th>
            <th>Name</th>
            <th>Mobile Number</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
  {checkins.map((checkin,index) => (
    <tr key={index}>
      <td>{checkin.person_id}</td>
      <td>{checkin.name}</td>
      <td>{checkin.mobile}</td>
      <td>{checkin.email}</td>
      <td>
        <button onClick={() => handleEmployeeCheckOut(checkin.person_id, checkin.name, checkin.mobile, checkin.email)}>
          Check Out
        </button>
      </td>
    </tr>
  ))}
</tbody>
      </table>
      <h3>Guests</h3>
      <table>
        <thead>
          <tr>
            <th>Guest Name</th>
            <th>Mobile Number</th>
            <th>Destination</th>
            <th>Meeting Person</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
  {guests.map((guest,index) => (
    <tr key={index}>
      <td>{guest.name}</td>
      <td>{guest.mobile}</td>
      <td>{guest.destination}</td>
      <td>{guest.meeting_person}</td>
      <td>
        <button onClick={() => handleGuestCheckOut(guest.person_id, guest.name, guest.mobile, guest.guestDestination, guest.guestMeetingPerson)}>
          Check Out
        </button>
      </td>
    </tr>
  ))}
</tbody>

      </table>
    </div>
  );
}

export default EmployeeList;